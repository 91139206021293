import styled from "styled-components";

export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px 0;
  background-color: #785598;
  color: white;
  font-size: 12px;
  font-weight: 300;
`;
